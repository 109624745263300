<template>
  <b-card no-body class="path-explanation">
    <b-card-body class="p-0">
      <div class="d-flex flex-row align-items-center mb-3">
        <span class="path-explanation__image__wrapper d-inline mr-3">
          <icon-series class="path-explanation__image" />
        </span>
        <h3 class="d-inline w-color-white">Čo je Séria?</h3>
      </div>
      <b-card-text v-if="isAdmin" class="path-explanation__text w-color-white">
        Niektoré témy chcete preskúmať hlbšie. Preto sme vytvorili Sériu, ktorá
        sa skladá z viacerých klasických video kurzov na jednu spoločnú tému. Po
        úspešnom zvládnutí Série vás čaká diplom.
      </b-card-text>
      <b-card-text v-if="!isAdmin" class="path-explanation__text w-color-white">
        Niektoré témy chceš určite preskúmať hlbšie. Preto sme vytvorili Sériu,
        ktorá sa skladá z viacerých klasických video kurzov na jednu spoločnú
        tému. Po úspešnom zvládnutí Série ťa čaká diplom.
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "icon-series": () => import("/assets/icons/iconSeries.svg?inline"),
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user && this.user.type === "teacher";
    },
  },
};
</script>

<style lang="scss" scoped>
.path-explanation {
  padding: 1.875rem 2.3125rem;
  background-color: #eb1b69;
  border-radius: 10px;

  .path-explanation__image__wrapper {
    width: 25px;

    .path-explanation__image {
      width: 100%;
      height: auto;
    }
  }
}
</style>
